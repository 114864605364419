
import { defineComponent, computed, onMounted, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import ArcAside from "@/core/layout/aside/Aside.vue";
import ArcHeader from "@/core/layout/header/Header.vue";
import ArcFooter from "@/core/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import ArcToolbar from "@/core/layout/toolbar/Toolbar.vue";
import ArcScrollTop from "@/core/layout/extras/ScrollTop.vue";
import Loader from "@/core/components/Loader.vue";
import ArcToolButtons from "@/core/layout/extras/ToolButtons.vue";
import { Actions } from "@/core/store/enums/StoreEnums";
import { MenuComponent } from "@/core/assets/ts/components";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { reinitializeComponents } from "@/core/plugins/arcui";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

export default defineComponent({
  name: "Layout",
  components: {
    ArcAside,
    ArcHeader,
    ArcFooter,
    ArcToolbar,
    ArcScrollTop,
    ArcToolButtons,
    Loader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      nextTick(() => {
        reinitializeComponents();
      });

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        nextTick(() => {
          reinitializeComponents();
        });
        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
    };
  },
});
